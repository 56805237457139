import { PayloadAction } from "../actions/PayloadAction";
import { GET_CUSTOMER_PREFERENCES, UPDATE_CUSTOMER_SELECTION_SUCCESS } from "../actions/CustomerPreferencesActions";
import PreferenceDto from "../models/PreferenceDto";
import PreferenceMapDto from "../models/PreferenceMapDto";
import PreferenceQuestionDto from "../models/PreferenceQuestionDto";
import PreferenceOptionDto from "../models/PreferenceOptionDto";

export interface ICustomerPreferencesReducerState {
  preference?: PreferenceDto;
}

export const initialState: ICustomerPreferencesReducerState = {};

export default class CustomerPreferencesReducer {
  private static readonly INITIAL_STATE: ICustomerPreferencesReducerState = initialState;

  public static reducer(
    state: ICustomerPreferencesReducerState = CustomerPreferencesReducer.INITIAL_STATE,
    action: PayloadAction<any>
  ): ICustomerPreferencesReducerState {
    switch (action.type) {
      case UPDATE_CUSTOMER_SELECTION_SUCCESS:
        return {
          ...state,
          preference: updatePreference(state.preference, action.payload),
        };
      case GET_CUSTOMER_PREFERENCES:
        return { ...state, preference: sortPreference(action.payload) };
      default:
        return state;
    }
  }
}

function sortPreference(preference: PreferenceDto): PreferenceDto {
  preference.questions
    .sort(sortPreferenceQuestionByPosition)
    .map((p) => p.options.sort(sortPreferenceOptionByPosition));
  return preference;
}

const sortPreferenceQuestionByPosition = (a: PreferenceQuestionDto, b: PreferenceQuestionDto) =>
  (a.position || 0) - (b.position || 0);

const sortPreferenceOptionByPosition = (a: PreferenceOptionDto, b: PreferenceOptionDto) =>
  (a.position || 0) - (b.position || 0);

function updatePreference(preference: PreferenceDto | undefined, payload: PreferenceMapDto): PreferenceDto | undefined {
  if (preference) {
    preference.questions.forEach((question) =>
      question.options.forEach((option) => (option.selected = option.optionNumber === payload[question.questionNumber]))
    );
  }
  return preference;
}
