import React, { PropsWithChildren } from "react";
import { Container } from "reactstrap";
import { Header } from "./Header";

export class HeaderLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <div
          style={{
            boxShadow: "-1rem 1rem 1rem -1rem rgba(0, 0, 0, 0.06), 1rem 1rem 1rem -1rem rgba(0, 0, 0, 0.06)",
          }}
        >
          <Container
            style={{
              maxWidth: 1200,
            }}
          >
            <Header />
          </Container>
        </div>
        <Container style={{ marginTop: 32 }}>{this.props.children}</Container>
      </>
    );
  }
}
