import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Form } from "react-final-form";
import { thunkCancelMembership } from "../../store/actions/MembershipCancellationActions";

interface MembershipCancellationDialogProps {
  setMembershipCancellationDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

class MembershipCancellationDialog extends React.Component<MembershipCancellationDialogProps & ThunkProps> {
  state = {
    redirect: false,
  };

  backToOverview = () =>
    this.setState({
      redirect: true,
    });

  cancelCustomerMembership = async () => {
    const { thunkCancelMembership, setMembershipCancellationDialogVisible } = this.props;
    await thunkCancelMembership();
    setMembershipCancellationDialogVisible(false);
  };

  render() {
    const { setMembershipCancellationDialogVisible } = this.props;
    return (
      <Modal onClose={() => setMembershipCancellationDialogVisible(false)} isOpen={true}>
        <Form
          onSubmit={this.cancelCustomerMembership}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader>Jetzt Vorteilskarte kündigen</ModalHeader>

              <ModalBody>
                <p>
                  <strong>Hinweis:</strong> Die Kündigung Ihrer Karte erfolgt zum morgigen Tag. Ihre persönlichen Daten
                  werden aus unserem Vorteilskartenprogramm gelöscht. Bitte beachten Sie, dass die Kündigung nicht
                  rückgängig gemacht werden kann.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setMembershipCancellationDialogVisible(false)} variant="contained">
                  Zurück
                </Button>
                <Button variant="contained" type="submit" disabled={submitting}>
                  Mitglied kündigen
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCancelMembership,
    },
    dispatch
  );

const mapStateToProps = () => ({});

type ThunkProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCancellationDialog);
