import { parseResponse } from "../store/actions/LoginActions";
import { tokenRequestOptions } from "../store/actions/LoginActions";
import {
  BonusXpressInteractionDto,
  BonusResponseDto,
  CustomerBonusInfoDto,
  PaymentInteractionDto,
  RedemptionDto,
  ReversalInteractionDto,
} from "../store/models/TouchpointDto";
import { fetchWithTenant } from "./ApiUtils";

const REACT_APP_SERVER_URL = window.env.REACT_APP_SERVER_URL || process.env.REACT_APP_SERVER_URL;

const REACT_APP_CUSTOMER_INTERACTION_URL =
  window.env.REACT_APP_CUSTOMER_INTERACTION_URL || process.env.REACT_APP_CUSTOMER_INTERACTION_URL;

const customerInteractionUrl = REACT_APP_CUSTOMER_INTERACTION_URL || `${REACT_APP_SERVER_URL}/customerinteraction/v1`;

const objectToQueryString = (obj: object) => {
  const entries = Object.entries(obj).filter(([_, value]) => value !== undefined);
  return entries.length ? `?${entries.map(([key, value]) => key + "=" + value).join("&")}` : "";
};

export const TouchpointApi = {
  getCustomerBonusInfo: async (
    identType: "customerNumber" | "externalCustomerId",
    customerIdent: string,
    externalUnitNumber: string
  ): Promise<CustomerBonusInfoDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${customerInteractionUrl}/customers/${customerIdent}${objectToQueryString({ identType, externalUnitNumber })}`,
      requestOptions
    ).then(parseResponse);
  },
  createPaymentInteraction: async (interaction: PaymentInteractionDto): Promise<BonusResponseDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerInteractionUrl}/paymentinteractions?response=short`, {
      ...requestOptions,
      body: JSON.stringify(interaction),
    }).then(parseResponse);
  },
  createBonusXpressInteraction: async (interaction: BonusXpressInteractionDto): Promise<BonusResponseDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerInteractionUrl}/bonusxpressinteractions?response=short`, {
      ...requestOptions,
      body: JSON.stringify(interaction),
    }).then(parseResponse);
  },
  redeemCoupon: async (redemption: RedemptionDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerInteractionUrl}/coupons/${redemption.couponCode}/redemption`, {
      ...requestOptions,
      body: JSON.stringify(redemption),
    }).then(parseResponse);
  },
  createReversalInteraction: async (interaction: ReversalInteractionDto): Promise<BonusResponseDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerInteractionUrl}/reversalinteractions?response=short`, {
      ...requestOptions,
      body: JSON.stringify(interaction),
    }).then(parseResponse);
  },
};
