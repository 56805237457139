import { PayloadAction } from "../actions/PayloadAction";
import { CREATE_INTERACTION, GET_CUSTOMER_BONUS_INFO, RESET_INTERACTION } from "../actions/TouchpointActions";
import { ReceiptDetailDto } from "../models/CustomerInteractionDto";
import { CustomerBonusInfoDto, BonusResponseDto, CouponDto, EmbeddedCouponDto } from "../models/TouchpointDto";

export interface ITouchpointReducerState {
  externalCustomerId?: string;
  customerNumber?: string;
  externalReceiptId: string;
  externalReferenceReceiptId?: string;
  externalUnitNumber: string;
  interactionTimestamp: string;
  paymentAmount: number;
  reversalAmount?: number;
  bonusXpressCashValue?: number;
  bonusXpressReversalCashValue?: number;
  coupons?: EmbeddedCouponDto[];
  selectedCoupons?: CouponDto[];
  paymentResponse?: BonusResponseDto;
  reversalResponse?: BonusResponseDto;
  customerBonusInfo?: CustomerBonusInfoDto;
  receiptDetails: ReceiptDetailDto[];
}

export const initialState: ITouchpointReducerState = {} as ITouchpointReducerState;

export default class InteractionReducer {
  private static readonly INITIAL_STATE: ITouchpointReducerState = initialState;

  public static reducer(
    state: ITouchpointReducerState = InteractionReducer.INITIAL_STATE,
    action: PayloadAction<any>
  ): ITouchpointReducerState {
    switch (action.type) {
      case RESET_INTERACTION:
        return {} as ITouchpointReducerState;
      case CREATE_INTERACTION:
        return action.payload;
      case GET_CUSTOMER_BONUS_INFO:
        return {
          ...state,
          customerBonusInfo: action.payload,
        };
      default:
        return state;
    }
  }
}
