import { PayloadAction } from "../actions/PayloadAction";
import {
  REGISTRATION_SUCCESS,
  REGISTRATION_DISPLAY_CONFIG,
  REGISTRATION_FIELD_CONFIG,
} from "../actions/RegistrationActions";
import { RegistrationDto } from "../models/RegistrationDto";
import { CustomerDto } from "../models/CustomerDto";
import AddressDto from "../models/AddressDto";
import { FieldConfigDto } from "../models/FieldConfigDto";
import { ConfigDto } from "../models/ConfigDto";

export interface IRegistrationReducerState {
  registration: RegistrationDto;
  registrationDisplayConfig: ConfigDto[];
  registrationFieldConfig: FieldConfigDto[];
  customer?: CustomerDto;
}

export const initialState: IRegistrationReducerState = {
  registration: {
    login: {
      username: "",
      password: "",
      passwordConfirm: "",
    },
    mailAddress: "",
    address: {
      country: "DE",
    } as AddressDto,
    acceptPrivacyPolicy: false,
    acceptTermsOfUse: false,
    emailCorrespondenceAllowed: false,
    letterCorrespondenceAllowed: true,
  },
  registrationFieldConfig: [],
  registrationDisplayConfig: [],
};

export default class RegistrationReducer {
  private static readonly INITIAL_STATE: IRegistrationReducerState = initialState;

  public static reducer(
    state: IRegistrationReducerState = RegistrationReducer.INITIAL_STATE,
    action: PayloadAction<any>
  ): IRegistrationReducerState {
    switch (action.type) {
      case REGISTRATION_SUCCESS:
        return {
          ...state,
          customer: action.payload,
        };
      case REGISTRATION_DISPLAY_CONFIG:
        return {
          ...state,
          registrationDisplayConfig: action.payload,
        };
      case REGISTRATION_FIELD_CONFIG:
        return {
          ...state,
          registrationFieldConfig: action.payload,
        };
      default:
        return state;
    }
  }
}
