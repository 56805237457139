import { ValidationErrors } from "final-form";
import { ConsentDto } from "../../store/models/ConsentDto";
import { MANDATORY_TEXT } from "../registration/Validation";

export function validateMandatory(errors: ValidationErrors, consent: ConsentDto) {
  if (!errors) return;
  if (!consent.externalCustomerId) {
    errors.externalCustomerId = MANDATORY_TEXT;
  }

  if (!consent.dateOfBirth) {
    errors.dateOfBirth = MANDATORY_TEXT;
  }

  if (!consent.participationConsent) {
    errors.participationConsent = MANDATORY_TEXT;
  }
}

export function validateExternalCustomerId(errors: ValidationErrors, externalCustomerId: string) {
  if (!errors) return;
  if (!/^\d{1,9}$/.test(externalCustomerId)) {
    errors.externalCustomerId = "Die Vorteilskartennummer muss eine max. neunstellige Zahl sein";
  }
}
