import { ReceiptDetailDto } from "../../store/models/CustomerInteractionDto";

export const receiptDetailExamplesTamaris: ReceiptDetailDto[] = [
  {
    amount: "1.000",
    salesPrice: "49.95",
    originalPrice: "49.95",
    itemInfo: {
      productGroupNumber: 3000,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 300030,
      subGroupDesc: "Blusen",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Bluse - braun",
      refNumber: "TAW0970-13445",
      rating: 5,
      votings: 231,
      organic: true,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "37690100",
    },
  },
  {
    amount: "1.000",
    salesPrice: "69.95",
    originalPrice: "69.95",
    itemInfo: {
      productGroupNumber: 2000,
      productGroupDesc: "Sneaker",
      subGroupNumber: 200020,
      subGroupDesc: "Sneaker - low",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Sneaker - rosa",
      refNumber: "1-23741-43-514",
      rating: 5,
      votings: 14,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "4049602357187",
    },
  },
  {
    amount: "1.000",
    salesPrice: "65.95",
    originalPrice: "89.95",
    itemInfo: {
      productGroupNumber: 1000,
      productGroupDesc: "Stiefel",
      subGroupNumber: 100010,
      subGroupDesc: "Plateaustiefel",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Stiefel - schwarz",
      refNumber: "1-25600-41-001",
      rating: 5,
      votings: 15,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "40412357187",
    },
  },
  {
    amount: "1.000",
    salesPrice: "35.95",
    originalPrice: "35.95",
    itemInfo: {
      productGroupNumber: 4000,
      productGroupDesc: "Accessoires",
      subGroupNumber: 400010,
      subGroupDesc: "Gürtel",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Gürtel - braun",
      refNumber: "TB0002L01-660",
      rating: 5,
      votings: 2,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "37690100",
    },
  },
  {
    amount: "1.000",
    salesPrice: "75.95",
    originalPrice: "109.95",
    itemInfo: {
      productGroupNumber: 1100,
      productGroupDesc: "Stiefeletten",
      subGroupNumber: 110020,
      subGroupDesc: "Chelsea Boots",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Leder Chelsea Boot - schwarz",
      refNumber: "1-25837-43-003",
      rating: 4.5,
      votings: 10,
      organic: true,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "4049602357187",
    },
  },
  {
    amount: "1.000",
    salesPrice: "35.95",
    originalPrice: "35.95",
    itemInfo: {
      productGroupNumber: 4000,
      productGroupDesc: "Accessoires",
      subGroupNumber: 400020,
      subGroupDesc: "Taschen",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Umhängetasche - blau",
      refNumber: "33720-536",
      rating: 5,
      votings: 231,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "40412357187",
    },
  },
  {
    amount: "1.000",
    salesPrice: "49.95",
    originalPrice: "79.95",
    itemInfo: {
      productGroupNumber: 1100,
      productGroupDesc: "Stiefeletten",
      subGroupNumber: 110040,
      subGroupDesc: "Absatzstiefeletten",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Stiefelette - schwarz",
      refNumber: "1-25837-43-004",
      rating: 5,
      votings: 7,
      organic: true,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "453976856",
    },
  },
  {
    amount: "1.000",
    salesPrice: "99.95",
    originalPrice: "99.95",
    itemInfo: {
      productGroupNumber: 1100,
      productGroupDesc: "Stiefeletten",
      subGroupNumber: 110020,
      subGroupDesc: "Chelsea Boots",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Leder Chelsea Boot - braun",
      refNumber: "1-25849-43-360",
      rating: 4.5,
      votings: 13,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "3456897345789",
    },
  },
  {
    amount: "1.000",
    salesPrice: "199.95",
    originalPrice: "199.95",
    itemInfo: {
      productGroupNumber: 3000,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 300040,
      subGroupDesc: "Jacken",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Lederjacke - schwarz",
      refNumber: "TAW0002-80009",
      rating: 4,
      votings: 1,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "3456897345780",
    },
  },
];
