import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkForgotPassword } from "../../store/actions/PasswordActions";
import { ForgotPasswordDto } from "../../store/models/ForgotPasswordDto";
import { IStore } from "../../store/rootReducer";
import { CenterLayout } from "../../themes/SwitchTheme";
import { UrlPathLogin } from "../../url";
import { nameof } from "../app/Utils";
import NotificationList from "../notification/NotificationList";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";

export type ForgotPasswordPageThunkProps = ReturnType<typeof mapDispatchToProps>;

class ForgotPasswordPage extends React.Component<ForgotPasswordPageThunkProps> {
  validate = (forgotPassword: ForgotPasswordDto) => {
    const errors: ValidationErrors = {};
    if (!forgotPassword?.mailAddress) errors.mailAddress = "Bitte E-Mail Adresse eingeben!";

    return errors;
  };
  render() {
    const { thunkForgotPassword } = this.props;
    return (
      <CenterLayout>
        <NotificationList />
        <div className="jumbotron">
          <h1>Passwort vergessen?</h1>
          <Form
            onSubmit={thunkForgotPassword}
            initialValues={{} as ForgotPasswordDto}
            validate={this.validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <p>
                    Geben Sie Ihre E-Mail-Adresse ein und klicken Sie auf Absenden. Danach werden wir Ihnen eine E-Mail
                    mit weiteren Instruktionen zusenden.
                  </p>
                </Row>
                <Row>
                  <Col>
                    <PaliFinalFormInput
                      type="email"
                      name={nameof<ForgotPasswordDto>("mailAddress")}
                      label="E-Mail-Adresse"
                      autoComplete="email"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button color="primary" type="submit" className="float-right">
                      Absenden
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          />
        </div>
        <Row>
          <Col md={12}>
            <div className="float-right">
              <NavLink to={UrlPathLogin}>Zurück zum Login</NavLink>
            </div>
          </Col>
        </Row>
      </CenterLayout>
    );
  }
}
const mapStateToProps = (state: IStore) => ({});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkForgotPassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
