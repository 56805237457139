export enum CustomerInteractionType {
  ADD_PREFERENCES = "ADD_PREFERENCES",
  REGISTER = "REGISTER",
  ADD_ADDRESS = "ADD_ADDRESS",
  FRIEND_INVITATION = "FRIEND_INVITATION",
  FAMILY_INVITATION = "FAMILY_INVITATION",
  CONSENT = "CONSENT",
}

export function formatCustomerInteractionType(customerInteractionType: CustomerInteractionType): string {
  switch (customerInteractionType) {
    case CustomerInteractionType.ADD_ADDRESS:
      return "Adressdaten angegeben";
    case CustomerInteractionType.ADD_PREFERENCES:
      return "Präferenzfragen beantwortet";
    case CustomerInteractionType.REGISTER:
      return "Registrierung";
    case CustomerInteractionType.FAMILY_INVITATION:
      return "Familieneinladung";
    case CustomerInteractionType.FRIEND_INVITATION:
      return "Freundschaftswerbung";
    case CustomerInteractionType.CONSENT:
      return "Zustimmung zum Programm";
    default:
      return customerInteractionType;
  }
}
