import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkLogin } from "../../store/actions/LoginActions";
import { thunkRegistration, thunkGetRegistrationDisplayConfig } from "../../store/actions/RegistrationActions";
import { IStore } from "../../store/rootReducer";
import { RegistrationForm } from "./RegistrationForm";
import { useMountEffect } from "../app/Utils";

const RegistrationFormContainer = (props: RegistrationFormThunkProps) => {
  const [loadingData, setLoadingData] = useState(false);
  useMountEffect(() => {
    async function getRegistrationDisplayConfig() {
      if (!props.registrationDisplayConfig?.length) {
        await props.thunkGetRegistrationDisplayConfig();
        setLoadingData(true);
      }
    }
    getRegistrationDisplayConfig();
  });
  return <>{loadingData && <RegistrationForm {...props} />}</>;
};

const mapStateToProps = (state: IStore) => ({
  registration: state.registration.registration,
  branchStores: state.operationalUnit.branchStores,
  registrationDisplayConfig: state.registration.registrationDisplayConfig,
  registrationFieldConfig: state.registration.registrationFieldConfig,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetRegistrationDisplayConfig, thunkRegistration, thunkLogin }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
export type RegistrationFormThunkProps = ConnectedProps<typeof connector>;
export default connector(RegistrationFormContainer);
