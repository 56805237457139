import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkBlockCustomer } from "../../store/actions/BlockCustomerAction";
import { IStore } from "../../store/rootReducer";
import { MenuLayout } from "../../themes/SwitchTheme";

type ThunkProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class BlockCustomerPage extends React.Component<ThunkProps> {
  render() {
    const { thunkBlockCustomer, result } = this.props;
    return !result ? (
      <MenuLayout>
        <h1>Karte sperren</h1>
        <p>
          Wenn Sie Ihre Karte verloren haben oder sie einfach sperren möchten, können Sie dies hier tun. Um Ihre Karte
          zu reaktivieren, müssen Sie den Kundenservice kontaktieren.
        </p>
        <Form
          onSubmit={(form) => thunkBlockCustomer()}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={12} className="mt-3 mb-5">
                  <Button type="submit">Karte sperren</Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </MenuLayout>
    ) : (
      <Row>
        <Col className="d-flex justify-content-center py-5">
          <b>Ihre Karte ist nun gesperrt und kann keine Bonuspunkte mehr sammeln oder ausgeben.</b>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkBlockCustomer }, dispatch);

const mapStateToProps = (state: IStore) => ({
  result: state.blockCustomer.result,
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockCustomerPage);
