import { ValidationErrors } from "final-form";
import { ChangePasswordDto } from "../../store/models/ChangePasswordDto";
import { MANDATORY_TEXT } from "../registration/Validation";

export function validateForm(errors: ValidationErrors, changePassword: ChangePasswordDto) {
  if (!errors) return;
  if (!changePassword.oldPassword) {
    errors.oldPassword = MANDATORY_TEXT;
  }
  if (!changePassword.newPassword) {
    errors.newPassword = MANDATORY_TEXT;
  }
  if (!changePassword.confirmNewPassword) {
    errors.confirmNewPassword = MANDATORY_TEXT;
  }
  if (
    changePassword.oldPassword &&
    changePassword.newPassword &&
    changePassword.oldPassword === changePassword.newPassword
  ) {
    errors.newPassword = "Aktuelles und neues Kennwort können nicht identisch sein";
  }
  if (
    changePassword.newPassword &&
    changePassword.confirmNewPassword &&
    changePassword.newPassword !== changePassword.confirmNewPassword
  ) {
    errors.confirmNewPassword = "Die Passwortbestätigung ist nicht identisch.";
  }
}
