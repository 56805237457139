import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import { UrlPathBonifiedInteractions, UrlPathLogin, UrlPathMain } from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logo from "../images/logo.svg";
import Menu from "./Menu";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header style={{ position: "fixed", width: "100%", zIndex: 1001 }}>
      <Navbar expand="xs">
        <Nav className="ml-auto" navbar>
          {isLoggedIn() && <Menu />}
          <NavbarBrand tag={isLoggedIn() ? Link : undefined} to={isLoggedIn() ? UrlPathMain : undefined}>
            <img
              src={logo}
              style={{
                height: 93.359,
                width: 285,
              }}
              alt="Futterhaus"
            />
          </NavbarBrand>
          {isLoggedIn() && (
            <>
              <NavItem>
                <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
                  Bonuspunkte:{" "}
                  <b>
                    <BonusPoints />
                  </b>{" "}
                </NavLink>
              </NavItem>
            </>
          )}
          <NavItem>
            <SelectTheme />
          </NavItem>
          <NavItem>
            <Button color="link" onClick={logoutAndRedirect}>
              Logout
            </Button>
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};
