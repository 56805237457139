import { ChangePasswordDto } from "../models/ChangePasswordDto";
import { PayloadAction } from "../actions/PayloadAction";

export interface IChangePasswordReducerState {
  changePassword: ChangePasswordDto;
}

export const initialState: IChangePasswordReducerState = {
  changePassword: {
    customerNumber: "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
};

export default class ChangePasswordReducer {
  private static readonly INITIAL_STATE: IChangePasswordReducerState = initialState;

  public static reducer(
    state: IChangePasswordReducerState = ChangePasswordReducer.INITIAL_STATE,
    action: PayloadAction<ChangePasswordDto>
  ): IChangePasswordReducerState {
    switch (action.type) {
      default:
        return ChangePasswordReducer.INITIAL_STATE;
    }
  }
}
