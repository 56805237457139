import { useEffect } from "react";
import { ensureToken } from "../../store/actions/LoginActions";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { TokenDto } from "../../store/models/LoginDto";

const ChatbotKaia = () => {
  const getToken = async (): Promise<TokenDto> => {
    const token = await ensureToken();
    if (!token) {
      thunkCreateErrorNotification("No token found!", "error");
      throw new Error("No token found!");
    }
    return token;
  };

  useEffect(() => {
    const initializeChatBot = async () => {
      const token = await getToken();
      const script = document.createElement("script");
      script.src = "https://static-production-wjs.brandslisten.com/kaia/out.js";
      script.async = true;
      script.defer = true;
      script.id = "bl-chat-widget-bubble-loader";

      const div = document.createElement("div");
      div.id = "bl-chat-widget-bubble-button";
      div.setAttribute("config", "6749c795b6c4e83feecd5e73");
      div.setAttribute("host", "https://knistr.kaia.team");
      div.setAttribute("user-jwt", token ? token.access_token : "");

      document.body.appendChild(div);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(div);
        document.body.removeChild(script);
      };
    };

    initializeChatBot();
  }, []);

  return null;
};

export default ChatbotKaia;
