import { Field } from "react-final-form";
import { Button, Col, Label, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { formatEuro, nameof } from "../app/Utils";
import { CashDeskSimulatorProps } from "./CashDeskSimulator";
import newProduct from "./images/newProduct.png";
import { receiptDetailExamplesTamaris } from "./ReceiptDetailExamplesTamaris";

function getRatingIcon(val: number, rating: number): string {
  if (rating - val >= 0) return "bi-star-fill";
  if (rating - val >= -0.59) return "bi-star-half";
  return "bi-star";
}

export const ReceiptDetailPanelTamaris = () => (
  <>
    <Row>
      <Col md={9}>
        <Label for={nameof<CashDeskSimulatorProps>("receiptDetailIds")}>Produkte wählen</Label>
      </Col>
    </Row>
    <Row>
      <Field name={nameof<CashDeskSimulatorProps>("receiptDetailIds")} type="field">
        {(props) =>
          receiptDetailExamplesTamaris.map((example) => (
            <Col md={4} key={example.itemInfo.refNumber}>
              <Button
                color="link"
                onClick={() => {
                  return props.input.onChange(
                    props.input.value.includes(example.itemInfo.refNumber)
                      ? props.input.value.filter((refNumber: string) => refNumber !== example.itemInfo.refNumber)
                      : [...props.input.value, example.itemInfo.refNumber]
                  );
                }}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Toast
                  className={props.input.value.includes(example.itemInfo.refNumber) ? "bg-info" : ""}
                  style={{
                    textAlign: "center",
                    cursor: "hand",
                  }}
                >
                  <ToastHeader>
                    {example.itemInfo.organic && (
                      <img
                        src={newProduct}
                        alt="Bio-zertifiziert"
                        title="Bio-zertifiziert"
                        style={{ height: "21px", paddingRight: "10px" }}
                      />
                    )}
                    {example.itemInfo.supplierItemText}
                  </ToastHeader>
                  <ToastBody style={{ padding: 0 }}>
                    <img
                      alt={example.itemInfo.supplierItemText}
                      src={`/productImagesTamaris/${example.itemInfo.refNumber}.png`}
                      style={{ width: "100%" }}
                    />
                    {[1, 2, 3, 4, 5].map((val) => (
                      <i
                        key={val}
                        className={getRatingIcon(val, example.itemInfo.rating)}
                        style={{ paddingRight: 8 }}
                      />
                    ))}{" "}
                    ({example.itemInfo.votings})
                    <big style={{ paddingLeft: "128px" }}>
                      <b>{formatEuro(example.salesPrice)}</b>
                    </big>
                  </ToastBody>
                </Toast>
              </Button>
            </Col>
          ))
        }
      </Field>
    </Row>
  </>
);
