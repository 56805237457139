import { CouponDto } from "../models/CouponDto";
import { PayloadAction } from "../actions/PayloadAction";
import { FIND_COUPONS_BY_CUSTOMER_NUMBER, REDEEM_COUPON } from "../actions/CouponActions";

export interface ICouponReducerState {
  coupons: CouponDto[];
  redeemedCoupon?: CouponDto;
}

export const initialState: ICouponReducerState = {
  coupons: [],
};

export default class CouponReducer {
  private static readonly INITIAL_STATE: ICouponReducerState = initialState;

  public static reducer(
    state: ICouponReducerState = CouponReducer.INITIAL_STATE,
    action: PayloadAction<any>
  ): ICouponReducerState {
    switch (action.type) {
      case FIND_COUPONS_BY_CUSTOMER_NUMBER:
        return {
          ...state,
          coupons: action.payload,
        };
      case REDEEM_COUPON:
        return {
          ...state,
          coupons: state.coupons.map((coupon) =>
            coupon.couponNumber === action.payload?.couponNumber ? action.payload : coupon
          ),
          redeemedCoupon: action.payload,
        };
      default:
        return state;
    }
  }
}
