import React from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import { IStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkChangePassword } from "../../store/actions/ChangePasswordActions";

type ThunkProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ChangePasswordFormContainer extends React.Component<ThunkProps> {
  render() {
    const { changePassword, customerNumber, thunkChangePassword } = this.props;

    if (!customerNumber) {
      return null;
    }

    changePassword.changePassword.customerNumber = customerNumber;
    return <ChangePasswordForm handleSubmit={thunkChangePassword} changePassword={changePassword.changePassword} />;
  }
}

const mapStateToProps = (state: IStore) => ({
  changePassword: state.changePassword,
  customerNumber: state.customer.customer.customerNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkChangePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormContainer);
