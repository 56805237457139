import NotificationDto from "../models/NotificationDto";
import { PayloadAction } from "../actions/PayloadAction";
import { CREATE_NOTIFICATION, DISMISS_NOTIFICATION } from "../actions/NotificationActions";

export interface INotificationsReducerState {
  notifications: NotificationDto[];
}

export const initialState: INotificationsReducerState = {
  notifications: [],
};

export default class NotificationsReducer {
  private static readonly INITIAL_STATE: INotificationsReducerState = initialState;

  public static reducer(
    state: INotificationsReducerState = NotificationsReducer.INITIAL_STATE,
    action: PayloadAction<NotificationDto>
  ): INotificationsReducerState {
    switch (action.type) {
      case CREATE_NOTIFICATION:
        return {
          ...state,
          notifications: [...state.notifications, action.payload!],
        };
      case DISMISS_NOTIFICATION:
        return {
          ...state,
          notifications: state.notifications.filter((err) => err.id !== action.payload?.id),
        };
      default:
        return state;
    }
  }
}
