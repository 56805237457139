import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkResetPassword } from "../../store/actions/PasswordActions";
import { CenterLayout } from "../../themes/SwitchTheme";
import { UrlPathLogin } from "../../url";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";

import { ResetPasswordDto } from "../../store/models/ResetPasswordDto";
import { IStore } from "../../store/rootReducer";
import { nameof } from "../app/Utils";
import NotificationList from "../notification/NotificationList";

export type ResetPasswordPageThunkProps = ReturnType<typeof mapDispatchToProps>;

class ResetPasswordPage extends React.Component<ResetPasswordPageThunkProps> {
  validate = (resetPassword: ResetPasswordDto) => {
    const errors: ValidationErrors = {};
    if (!resetPassword?.newPassword) errors.newPassword = "Bitte ein sicheres Passwort eingeben!";

    return errors;
  };
  render() {
    const { thunkResetPassword } = this.props;
    return (
      <CenterLayout>
        <NotificationList />
        <div className="jumbotron mt-5">
          <h1>Passwort zurücksetzen</h1>
          <Form
            onSubmit={thunkResetPassword}
            initialValues={{} as ResetPasswordDto}
            validate={this.validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <p>Geben Sie Ihr neues Passwort ein.</p>
                </Row>
                <Row>
                  <Col>
                    <PaliFinalFormInput
                      type="password"
                      name={nameof<ResetPasswordDto>("newPassword")}
                      label="Neues Passwort"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button color="primary" type="submit" className="float-right">
                      Absenden
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          />
        </div>
        <Row>
          <Col md={12}>
            <div className="float-right">
              <NavLink to={UrlPathLogin}>Zurück zum Login</NavLink>
            </div>
          </Col>
        </Row>
      </CenterLayout>
    );
  }
}
const mapStateToProps = (state: IStore) => ({});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkResetPassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
