import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { LoginDto } from "../../store/models/LoginDto";
import { LoginDataDto, RegistrationDto } from "../../store/models/RegistrationDto";
import { nameof, nameof2 } from "../app/Utils";
import { PaliFinalFormCheckbox } from "../pali/PaliFinalFormCheckbox";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { getLabelText } from "./RegistrationForm";
import { RegistrationFormThunkProps } from "./RegistrationFormContainer";
import { isFieldNeeded } from "./Validation";

export default class Registration extends React.Component<RegistrationFormThunkProps> {
  render() {
    const { registrationFieldConfig } = this.props;
    return (
      <Card id="personal" className="mb-5">
        <CardBody>
          <CardTitle>Pflichtfelder</CardTitle>
          <Row>
            <Col md={6}>
              {isFieldNeeded(nameof2<RegistrationDto, LoginDataDto>("login", "username"), registrationFieldConfig) && (
                <PaliFinalFormInput
                  type="email"
                  name={nameof2<RegistrationDto, LoginDataDto>("login", "username")}
                  label={getLabelText(
                    "E-Mail",
                    nameof2<RegistrationDto, LoginDto>("login", "username"),
                    registrationFieldConfig
                  )}
                  autoComplete="email"
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(nameof<RegistrationDto>("dateOfBirth"), registrationFieldConfig) && (
                <PaliFinalFormInput
                  type="date"
                  name={nameof<RegistrationDto>("dateOfBirth")}
                  label={getLabelText("Geburtsdatum", nameof<RegistrationDto>("dateOfBirth"), registrationFieldConfig)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(nameof2<RegistrationDto, LoginDataDto>("login", "password"), registrationFieldConfig) && (
                <PaliFinalFormInput
                  type="password"
                  name={nameof2<RegistrationDto, LoginDataDto>("login", "password")}
                  label={getLabelText(
                    "Passwort",
                    nameof2<RegistrationDto, LoginDto>("login", "password"),
                    registrationFieldConfig
                  )}
                  autoComplete="new-password"
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(nameof2<RegistrationDto, LoginDataDto>("login", "password"), registrationFieldConfig) && (
                <PaliFinalFormInput
                  type="password"
                  name={nameof2<RegistrationDto, LoginDataDto>("login", "passwordConfirm")}
                  label={getLabelText(
                    "Passwort bestätigen",
                    nameof2<RegistrationDto, LoginDto>("login", "password"),
                    registrationFieldConfig
                  )}
                  autoComplete="new-password"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {isFieldNeeded(nameof<RegistrationDto>("acceptPrivacyPolicy"), registrationFieldConfig) && (
                <PaliFinalFormCheckbox
                  name={nameof<RegistrationDto>("acceptPrivacyPolicy")}
                  label="Ich bin auf die Datenschutzerklärung hingewiesen worden und akzeptiere diese."
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {isFieldNeeded(nameof<RegistrationDto>("acceptTermsOfUse"), registrationFieldConfig) && (
                <PaliFinalFormCheckbox
                  name={nameof<RegistrationDto>("acceptTermsOfUse")}
                  label="Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese."
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
