import API from "../../api/Api";
import CustomerPreferenceDto from "../models/CustomerPreferenceDto";
import PreferenceMapDto from "../models/PreferenceMapDto";
import { BooleanThunk, BooleanThunkAction } from "../rootReducer";
import { thunkGetBonusPointsInfo } from "./BonifiedInteractionsActions";
import { ensureToken } from "./LoginActions";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
export const UPDATE_CUSTOMER_SELECTION_SUCCESS = "UPDATE_CUSTOMER_SELECTION_SUCCESS";
export const GET_CUSTOMER_PREFERENCES = "GET_CUSTOMER_PREFERENCES";

export const thunkUpdateCustomerPreferences =
  (preferencesMap: PreferenceMapDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const customerPreferences: CustomerPreferenceDto[] = [];
      for (const key in preferencesMap) {
        const value = preferencesMap[key];
        if (value instanceof Array) {
          value.forEach((val) => {
            customerPreferences.push({
              selectedQuestionNumber: key,
              selectedOptionNumber: val,
            });
          });
        } else if (value) {
          customerPreferences.push({
            selectedQuestionNumber: key,
            selectedOptionNumber: value,
          });
        }
      }
      const token = await ensureToken();
      if (!token) return false;
      const updatedCustomer = await API.updateCustomerPreferences(customerPreferences);
      dispatch({
        type: UPDATE_CUSTOMER_SELECTION_SUCCESS,
        payload: updatedCustomer,
      });
      // update bonus points with delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(thunkGetBonusPointsInfo() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification(`Die Kundenpräferenzen wurden gespeichert`));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Speichern der Kundenpräferenzen", e));
      return false;
    }
  };

export const thunkGetCustomerPreferences = (): BooleanThunk => async (dispatch) => {
  try {
    const customerPreferences = await API.getCustomerPreferences();
    dispatch({
      type: GET_CUSTOMER_PREFERENCES,
      payload: customerPreferences,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Kundenpräferenzen", e));
    return false;
  }
};
