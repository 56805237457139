import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import { UrlPathBonifiedInteractions, UrlPathLogin, UrlPathMain } from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logoutIcon from "../images/Download.svg";
import logo from "../images/logo-ullapopken.svg";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <Navbar expand="xs">
        <NavbarBrand tag={isLoggedIn() ? Link : undefined} to={isLoggedIn() ? UrlPathMain : undefined}>
          <img
            src={logo}
            style={{
              height: 62,
              paddingLeft: 430,
            }}
            alt="Ulla Popken"
          />
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          {isLoggedIn() && (
            <>
              <NavItem>
                <NavLink tag={Link} to={UrlPathBonifiedInteractions} style={{ textAlign: "center" }}>
                  <div style={{ marginTop: "6px", marginBottom: "-2px" }}>
                    <b>
                      <BonusPoints />
                    </b>
                  </div>
                  <span style={{ fontSize: 10 }}>Deine Punkte</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="link" onClick={logoutAndRedirect}>
                  <img src={logoutIcon} alt="Logout" />
                  <br />
                  <span style={{ fontSize: 10 }}>Logout</span>
                </Button>
              </NavItem>
            </>
          )}
          <NavItem>
            <SelectTheme />
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};
