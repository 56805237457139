import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, NavbarBrand, NavItem, NavLink } from "reactstrap";
import Menu from "../../../components/app/Menu";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import { UrlPathBonifiedInteractions, UrlPathLogin, UrlPathMain } from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logo from "../images/konsum_logo.png";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <NavbarBrand tag={isLoggedIn() ? Link : undefined} to={isLoggedIn() ? UrlPathMain : undefined}>
        <img src={logo} alt="Konsum. Hier ist Heimat." width="200" height="200" />
      </NavbarBrand>

      <Menu vertical />
      <Nav vertical>
        {isLoggedIn() && (
          <>
            <NavLink>
              <Button color="link" onClick={logoutAndRedirect}>
                Logout
              </Button>
            </NavLink>
            <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
              Deine Bonuspunkte:{" "}
              <b>
                <BonusPoints />
              </b>
            </NavLink>
          </>
        )}
        <NavItem>
          <SelectTheme />
        </NavItem>
      </Nav>
    </header>
  );
};
