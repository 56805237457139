import { PayloadAction } from "../actions/PayloadAction";
import { BLOCK_CUSTOMER_SUCCESS } from "../actions/BlockCustomerAction";

export interface IBlockCustomerReducerState {
  result: boolean;
}

export const initialState: IBlockCustomerReducerState = {
  result: false,
};

export default class BlockCustomerReducer {
  private static readonly INITIAL_STATE: IBlockCustomerReducerState = initialState;

  public static reducer(
    state: IBlockCustomerReducerState = BlockCustomerReducer.INITIAL_STATE,
    action: PayloadAction<boolean>
  ): IBlockCustomerReducerState {
    switch (action.type) {
      case BLOCK_CUSTOMER_SUCCESS:
        return { result: true };
      default:
        return BlockCustomerReducer.INITIAL_STATE;
    }
  }
}
