import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Field } from "react-final-form";

interface PaliFinalFormCheckboxProps {
  name: string;
  label: string;
  readOnly?: boolean;
}

export class PaliFinalFormCheckbox extends React.Component<PaliFinalFormCheckboxProps> {
  render() {
    let { label, name, readOnly } = this.props;
    return (
      <Field name={name} type="checkbox">
        {({ input, meta }) => {
          const hasErrors = meta.error && meta.touched;
          return (
            <FormGroup className={hasErrors ? "has-error" : ""}>
              <Label for={name} className="check check--checkbox">
                {label}
                <Input {...input} type="checkbox" id={name} readOnly={readOnly} defaultChecked={input.value} />
                <div className="check__indicator"></div>
              </Label>
              {hasErrors && <span className="help-block">{meta.error}</span>}
            </FormGroup>
          );
        }}
      </Field>
    );
  }
}
