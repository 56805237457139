import React from "react";
import { PaliAlert } from "../pali/PaliAlert";
import NotificationDto from "../../store/models/NotificationDto";
import { formatGermanDateTime } from "../app/Utils";

interface NotificationAlertProps {
  notification: NotificationDto;
  beforeDismiss: (err: NotificationDto) => void;
}

export default class NotificationAlert extends React.Component<NotificationAlertProps> {
  render() {
    const { beforeDismiss, notification } = this.props;
    const { type, timestamp, message } = notification;
    const msg = `${formatGermanDateTime(timestamp)}  ${message}`;

    return <PaliAlert color={type} message={msg} beforeDismiss={() => beforeDismiss(notification)} />;
  }
}
