import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkDismissNotification } from "../../store/actions/NotificationActions";
import { connect } from "react-redux";
import NotificationAlert from "./NotificationAlert";
import React from "react";
import NotificationDto from "../../store/models/NotificationDto";
import { IStore } from "../../store/rootReducer";

type ThunkProps = ReturnType<typeof mapDispatchToProps>;
interface NotificationListProps {
  notifications?: NotificationDto[];
}
interface NotificationListState {
  notifications?: NotificationDto[];
}

class NotificationList extends React.Component<ThunkProps & NotificationListProps, NotificationListState> {
  constructor(props: ThunkProps & NotificationListProps) {
    super(props);
    this.state = { notifications: undefined };
  }
  render() {
    const { notifications, thunkDismissNotification } = this.props;
    if (!notifications || notifications.length === 0) return null;
    else
      return (
        <>
          {notifications.map((n) => {
            return <NotificationAlert notification={n} beforeDismiss={thunkDismissNotification} key={n.id} />;
          })}
        </>
      );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDismissNotification }, dispatch);

const mapStateToProps = (state: IStore) => ({
  notifications: state.notifications.notifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
