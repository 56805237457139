import { BonusPremiumDto } from "../models/BonusPremiumDto";
import { PayloadAction } from "../actions/PayloadAction";
import { FIND_ACTIVE_PREMIUMS } from "../actions/BonusPremiumActions";

export interface IBonusPremiumReducerState {
  bonusPremiums: BonusPremiumDto[];
}

export const initialState: IBonusPremiumReducerState = {
  bonusPremiums: [],
};

export default class BonusPremiumReducer {
  private static readonly INITIAL_STATE: IBonusPremiumReducerState = initialState;

  public static reducer(
    state: IBonusPremiumReducerState = BonusPremiumReducer.INITIAL_STATE,
    action: PayloadAction<BonusPremiumDto[]>
  ): IBonusPremiumReducerState {
    switch (action.type) {
      case FIND_ACTIVE_PREMIUMS:
        return {
          ...state,
          bonusPremiums: action.payload!,
        };
      default:
        return state;
    }
  }
}
