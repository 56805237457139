import { DateTimeFormatter, LocalDate, LocalDateTime, nativeJs, ZonedDateTime, ZoneOffset } from "js-joda";
import { EffectCallback, useEffect } from "react";

export const GERMAN_DATE_FORMAT = "dd.MM.yyyy";
export const GERMAN_TIME_FORMAT = "HH:mm:ss";

const apiDateFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd");
const germanDateFormatter = DateTimeFormatter.ofPattern(GERMAN_DATE_FORMAT);
const germanTimeFormatter = DateTimeFormatter.ofPattern(GERMAN_TIME_FORMAT);
const germanDateTimeFormatter = DateTimeFormatter.ofPattern(`${GERMAN_DATE_FORMAT} ${GERMAN_TIME_FORMAT}`);

export const formatGermanDate = (dateString?: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return LocalDate.from(nativeJs(date)).format(germanDateFormatter);
};

export const formatGermanTime = (dateString?: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return LocalDateTime.from(nativeJs(date)).format(germanTimeFormatter);
};

export const formatGermanDateTimeString = (dateTimeString?: string) => {
  if (!dateTimeString) return "";
  return formatGermanDateTime(new Date(dateTimeString));
};

export const formatGermanDateTime = (dateTime: Date) =>
  ZonedDateTime.from(nativeJs(dateTime)).format(germanDateTimeFormatter);

export const formatApiDate = (date: Date | null) => {
  if (!date || isNaN(date.valueOf())) return "";
  return LocalDate.from(nativeJs(date)).format(apiDateFormatter);
};

export const getCurrentDateAsString = (): string => LocalDate.now().toString();

export const getCurrentDateTimeAsString = (): string => ZonedDateTime.now(ZoneOffset.UTC).toString();

export const getToday = (): Date => {
  const date = new Date();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const getRandomLetter = (length: number) => {
  var result = "";
  for (var i = 0; i < length; i++) {
    result = result + String.fromCharCode(Math.floor(Math.random() * 26) + 65);
  }
  return result;
};

export const getRandomId = (): string => {
  return (
    getRandomLetter(3) +
    "-" +
    (Math.floor(Math.random() * 8999) + 1000) +
    "/" +
    LocalDate.now().format(DateTimeFormatter.ofPattern("ddMMyyyy"))
  );
};

export const getTomorrow = (): Date => {
  const date = new Date();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1));
};

export const isPositiveInteger = (str: string | number | undefined | null) => {
  var n = Math.floor(Number(str));
  return n !== Infinity && (String(n) === str || n === str) && n > 0;
};

export const isValidMailAddress = (mailAddress?: string) => {
  const mailAddressRegex = new RegExp("[a-zA-ZÀ-ÿ0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}");
  return mailAddress && mailAddressRegex.test(mailAddress);
};

export const formatNumber = (value: any): string => {
  if (typeof value === "string") value = value.replace(",", ".");
  return typeof value === "number" || !isNaN(parseFloat(value))
    ? numberFormatter.format(parseFloat(value)).replace(/,/g, ".")
    : "";
};

// we use english locale because german locale is sometimes missing
const numberFormatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const formatEuro = (value: any): string => {
  if (typeof value === "string") value = value.replace(",", ".");
  return (typeof value === "number" && !isNaN(value)) || !isNaN(parseFloat(value))
    ? germanSeparators(euroFormatter.format(parseFloat(value))) + "\xA0€"
    : "";
};

function germanSeparators(str: string) {
  str = str.replace(/,/g, ".");
  return str.replace(/\.([^.]*)$/, ",$1");
}

// we use english locale because german locale is sometimes missing
const euroFormatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

export const nameof = <T>(name: keyof T) => name;
export const nameof2 = <T1, T2>(name1: keyof T1, name2: keyof T2) => String(name1) + "." + String(name2);
// https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once/56767883#56767883
export const useMountEffect = (fun: EffectCallback) => useEffect(fun, []); // eslint-disable-line react-hooks/exhaustive-deps
