import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import { UrlPathBonifiedInteractions, UrlPathLogin, UrlPathMain } from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logoutIcon from "../images/Konto.svg";
import logo from "../images/tamarisLogo.svg";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <Navbar expand="xs" style={{ paddingTop: 19, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <NavbarBrand
          tag={isLoggedIn() ? Link : undefined}
          to={isLoggedIn() ? UrlPathMain : undefined}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
          <img
            src={logo}
            alt="Tamaris"
            width="468"
            height="30"
            style={{
              height: 63,
              paddingLeft: isLoggedIn() ? 200 : 150,
            }}
          />
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          {isLoggedIn() && (
            <>
              <NavItem>
                <NavLink tag={Link} to={UrlPathBonifiedInteractions} style={{ textAlign: "center" }}>
                  <div style={{ marginTop: "6px", marginBottom: "-2px" }}>
                    <b>
                      <BonusPoints />
                    </b>
                  </div>
                  <span style={{ fontSize: 10 }}>Deine Punkte</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="link" onClick={logoutAndRedirect}>
                  <img src={logoutIcon} style={{ width: 24 }} alt="Logout" />
                  <br />
                  <span style={{ fontSize: 10 }}>Logout</span>
                </Button>
              </NavItem>
            </>
          )}
          <NavItem>
            <div style={{ paddingTop: 16 }}>
              <SelectTheme />
            </div>
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};
