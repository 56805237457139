import React, { PropsWithChildren } from "react";
import { Container } from "reactstrap";
import Menu from "../../../components/app/Menu";
import NotificationList from "../../../components/notification/NotificationList";
import { Header } from "./Header";

export class MenuLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
          <NotificationList />
        </div>
        <div
          style={{
            boxShadow: "-1rem 1rem 1rem -1rem rgba(0, 0, 0, 0.06), 1rem 1rem 1rem -1rem rgba(0, 0, 0, 0.06)",
          }}
        >
          <Container
            style={{
              maxWidth: 1200,
            }}
          >
            <Header />
            <Menu />
          </Container>
        </div>
        <Container
          style={{
            minHeight: "100vh",
            maxWidth: 1200,
          }}
        >
          {this.props.children}
        </Container>
      </>
    );
  }
}
