import React from "react";
import { Field } from "react-final-form";
import { Button, Col, FormGroup, Label, Row, Toast, ToastBody } from "reactstrap";
import { CustomerBonusInfoDto } from "../../store/models/TouchpointDto";
import { formatEuro, formatNumber } from "../app/Utils";
import { calculatePaymentAmount, calculatePaymentAmountWithCoupons, CashDeskSimulatorProps } from "./CashDeskSimulator";

interface BonusXpressPanelProps {
  customerBonusInfo?: CustomerBonusInfoDto;
  values: CashDeskSimulatorProps;
}

export class BonusXpressPanel extends React.Component<BonusXpressPanelProps> {
  render() {
    const { customerBonusInfo, values } = this.props;
    if (!customerBonusInfo) return null;
    if (calculateXpressError(values, customerBonusInfo))
      return (
        <Row>
          <Col md={9}>
            <FormGroup>
              <Label>Bonus einlösen</Label>
              <p>
                <i>{calculateXpressError(values, customerBonusInfo)}</i>
              </p>
            </FormGroup>
          </Col>
        </Row>
      );
    return (
      <>
        <Row>
          <Col md={9}>
            <Label>
              Bonuspunkte einlösen{" "}
              <i>
                (Es sind {formatNumber(customerBonusInfo.availableBonusPoints)} von{" "}
                {formatNumber(customerBonusInfo.totalBonusPoints)} Punkten verfügbar)
              </i>
            </Label>
          </Col>
        </Row>
        <Row>
          <Field name="cashValue">
            {({ input }) =>
              [0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500].map((value, index, array) => {
                const xpress = calculateXpress(values, customerBonusInfo);
                if (value > xpress) {
                  if (index > 0 && array[index - 1] >= xpress) return null;
                  value = xpress;
                }
                return (
                  <Col xs={2} key={index}>
                    <Button
                      color="link"
                      onClick={() => input.onChange(input.value !== value ? value : undefined)}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      <Toast
                        className={input.value === value ? "bg-info" : ""}
                        style={{
                          textAlign: "center",
                          cursor: "hand",
                          width: "100%",
                        }}
                      >
                        <ToastBody>{formatEuro(value)}</ToastBody>
                      </Toast>
                    </Button>
                  </Col>
                );
              })
            }
          </Field>
        </Row>
      </>
    );
  }
}

const calculateXpressError = (values: CashDeskSimulatorProps, customerBonusInfo: CustomerBonusInfoDto): string => {
  if (!customerBonusInfo.bonusXpress) {
    return "BonusXPress ist nicht konfiguriert";
  }
  const availableBonusPoints = Number(customerBonusInfo.availableBonusPoints);
  const threshold = Number(customerBonusInfo.bonusXpress.threshold);
  if (availableBonusPoints < threshold) return `Sie benötigen noch ${threshold - availableBonusPoints} Bonuspunkte`;
  const paymentAmount = calculatePaymentAmount(values.receiptDetailIds);
  const paymentAmountWithCoupon = calculatePaymentAmountWithCoupons(paymentAmount, values.selectedCoupons);
  if (paymentAmountWithCoupon <= 0) return "nur bei positiver Rechnungssumme";
  return "";
};

const calculateXpress = (values: CashDeskSimulatorProps, customerBonusInfo: CustomerBonusInfoDto) => {
  const paymentAmount = calculatePaymentAmount(values.receiptDetailIds);
  const paymentAmountWithCoupon = calculatePaymentAmountWithCoupons(paymentAmount, values.selectedCoupons);
  if (!customerBonusInfo.bonusXpress) return 0;
  return Math.min(customerBonusInfo.bonusXpress.cashValue, Math.max(paymentAmountWithCoupon, 0));
};
