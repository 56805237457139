import { PayloadAction } from "../actions/PayloadAction";
import { GET_CUSTOMER_HISTORY, GET_BONUS_POINTS_INFO } from "../actions/BonifiedInteractionsActions";
import { BonusPointsDto } from "../models/BonusPointsDto";
import { CustomerHistoryDto } from "../models/HistoryDto";

export interface IBonifiedInteractionsReducerState {
  history: CustomerHistoryDto[];
  bonusPoints?: BonusPointsDto;
}

export const initialState: IBonifiedInteractionsReducerState = { history: [] };

export default class BonifiedInteractionsReducer {
  private static readonly INITIAL_STATE: IBonifiedInteractionsReducerState = initialState;

  public static reducer(
    state: IBonifiedInteractionsReducerState = BonifiedInteractionsReducer.INITIAL_STATE,
    action: PayloadAction<CustomerHistoryDto[] & BonusPointsDto>
  ): IBonifiedInteractionsReducerState {
    switch (action.type) {
      case GET_CUSTOMER_HISTORY:
        return {
          ...state,
          history: action.payload!,
        };
      case GET_BONUS_POINTS_INFO:
        return {
          ...state,
          bonusPoints: action.payload,
        };
      default:
        return state;
    }
  }
}
