import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import { UrlPathBonifiedInteractions, UrlPathLogin, UrlPathMain } from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logo from "../images/engelhorn_Logo.png";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <Navbar expand="xs" style={{ paddingTop: 19 }}>
        <div style={{ width: "33.33%" }}> </div>
        <NavbarBrand tag={isLoggedIn() ? Link : undefined} to={isLoggedIn() ? UrlPathMain : undefined}>
          <img
            src={logo}
            alt="Engelhorn"
            width="468"
            height="130"
            style={{
              height: "auto",
              width: 120,
            }}
          />
        </NavbarBrand>

        <Nav navbar style={{ width: "33.33%", justifyContent: "flex-end" }}>
          {isLoggedIn() && (
            <>
              <NavItem>
                <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
                  Deine Bonuspunkte:{" "}
                  <b>
                    <BonusPoints />
                  </b>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="link" onClick={logoutAndRedirect}>
                  Logout
                </Button>
              </NavItem>
            </>
          )}
          <NavItem>
            <SelectTheme />
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};
