import { FormApi } from "final-form";
import React from "react";
import { Field } from "react-final-form";
import { Button, Col, FormGroup, Label, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { CouponDto, CustomerBonusInfoDto } from "../../store/models/TouchpointDto";
import DateTag from "../app/DateTag";
import { CashDeskSimulatorProps } from "./CashDeskSimulator";

interface CouponPanelProps {
  customerBonusInfo?: CustomerBonusInfoDto;
  form: FormApi<CashDeskSimulatorProps, Partial<CashDeskSimulatorProps>>;
}

export class CouponPanel extends React.Component<CouponPanelProps> {
  render() {
    const { customerBonusInfo, form } = this.props;
    if (!customerBonusInfo) return null;
    return customerBonusInfo.coupons?.length ? (
      <>
        <Row>
          <Col md={6}>
            <Label>Coupons auswählen</Label>
          </Col>
        </Row>
        <Row>
          <Field name="selectedCoupons" type="field">
            {(props) =>
              customerBonusInfo.coupons.map((coupon) => (
                <Col md={4} key={coupon.couponCode}>
                  <Button
                    color="link"
                    onClick={() => {
                      form.mutators.clearCashValue();
                      return props.input.onChange(
                        props.input.value.includes(coupon)
                          ? props.input.value.filter((selectedCoupon: CouponDto) => selectedCoupon !== coupon)
                          : [...props.input.value, coupon]
                      );
                    }}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <Toast
                      className={props.input.value.includes(coupon) ? "bg-info" : ""}
                      style={{
                        textAlign: "center",
                        cursor: "hand",
                      }}
                    >
                      <ToastHeader>{coupon.couponCode}</ToastHeader>
                      <ToastBody>
                        {coupon.advantage.advantageText}
                        <br />
                        bis <DateTag date={coupon.expiryDate} />
                        <br />
                      </ToastBody>
                    </Toast>
                  </Button>
                </Col>
              ))
            }
          </Field>
        </Row>
      </>
    ) : (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Coupons auswählen</Label>
            <p>
              <i>Sie haben keine aktiven Coupons</i>
            </p>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}
