import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { FieldConfigDto } from "../../store/models/FieldConfigDto";
import { RegistrationDto } from "../../store/models/RegistrationDto";
import { UrlPathMain } from "../../url";
import NotificationList from "../notification/NotificationList";
import Contact from "./Contact";
import PersonalData from "./PersonalData";
import Registration from "./Registration";
import { RegistrationFormThunkProps } from "./RegistrationFormContainer";
import {
  isFieldMandatory,
  validateDateOfBirth,
  validateMandatory,
  validateName,
  validateRegistration,
} from "./Validation";

export function getLabelText(labelName: string, fieldName: string, registrationFieldConfig: FieldConfigDto[]) {
  return labelName + (isFieldMandatory(fieldName, registrationFieldConfig) ? " *" : "");
}
export const RegistrationForm = (props: RegistrationFormThunkProps) => {
  const navigate = useNavigate();
  const redirectToMain = () => {
    navigate(UrlPathMain);
    window.scrollTo(0, 0);
  };

  const register = async (values: RegistrationDto) => {
    const { thunkRegistration, thunkLogin } = props;
    const success = await thunkRegistration(values);
    if (success) {
      await thunkLogin(values.login);
      redirectToMain();
    }
  };

  const { registration, registrationFieldConfig } = props;

  const validate = (registration: RegistrationDto) => {
    const errors: ValidationErrors = {};

    validateMandatory(errors, registration, registrationFieldConfig);
    validateRegistration(errors, registration.login.password, registration.login.passwordConfirm);
    validateName(errors, registration.firstName, registration.lastName);
    validateDateOfBirth(errors, registration.dateOfBirth);

    return errors;
  };
  return (
    <div className="jumbotron">
      <h1>Registrierung</h1>
      <Form
        onSubmit={register}
        initialValues={registration}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Registration {...props} />
            <PersonalData {...props} />
            <Contact {...props} />
            <Row style={{ paddingBottom: 16 }}>
              <Col md={{ size: 12 }}>
                <NotificationList />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button type="submit">Registrieren</Button>
              </Col>
            </Row>
          </form>
        )}
      />
    </div>
  );
};
