import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import API from "../../api/Api";
import { IStore } from "../rootReducer";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const GET_OPERATIONAL_ROOT_UNIT_LIST = "GET_OPERATIONAL_ROOT_UNIT_LIST";
export const GET_BRANCH_STORE_LIST = "GET_BRANCH_STORE_LIST";

export const thunkGetOperationalRootUnitList =
  (): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
    try {
      const operationalUnits = await API.getOperationalRootUnitList();
      dispatch({
        type: GET_OPERATIONAL_ROOT_UNIT_LIST,
        payload: operationalUnits,
      });
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Laden der Filialen", e));
      return false;
    }
  };

export const thunkGetBranchStoreList = (): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
  try {
    const branchStores = await API.getBranchStoreList();
    dispatch({
      type: GET_BRANCH_STORE_LIST,
      payload: branchStores,
    });
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Filialen", e));
    return false;
  }
};
