import { connect } from "react-redux";
import { Button, Col, Row, Toast } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCreateReversalInteraction, thunkResetInteraction } from "../../store/actions/TouchpointActions";
import { BonusResponseDto } from "../../store/models/TouchpointDto";
import { IStore } from "../../store/rootReducer";
import { formatEuro, formatGermanDate, formatGermanTime, getCurrentDateTimeAsString, getRandomId } from "../app/Utils";
import { calculateCoupon, calculatePaymentAmountWithCouponAndXpress } from "./CashDeskSimulator";

type ThunkProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ReceiptSimulator = (props: ThunkProps) => {
  const { interaction, operationalUnits } = props;

  const formatBonusPoints = (paymentResponse?: BonusResponseDto, reversalResponse?: BonusResponseDto) => {
    const bonusPoints = reversalResponse ? -Number(reversalResponse.bonusPoints) : Number(paymentResponse?.bonusPoints);
    const bonusPointText = `${bonusPoints || "keine"} Bonuspunkt${bonusPoints !== 1 && "e"}`;
    return (reversalResponse ? `Wir haben ${bonusPointText} storniert.` : `Du sammelst ${bonusPointText}.`).padEnd(
      45,
      "\xa0"
    );
  };

  const formatTotalBonusPoints = (paymentResponse?: BonusResponseDto, reversalResponse?: BonusResponseDto): string => {
    const totalBonusPoints = Number((reversalResponse || paymentResponse)!.totalBonusPoints);
    return `Dein Punktestand beträgt nun ${totalBonusPoints}.`.padEnd(45, "\xa0");
  };

  const reversal = (hasExternalReferenceReceiptId: boolean) => {
    props.thunkCreateReversalInteraction(
      {
        externalCustomerId: interaction.externalCustomerId,
        customerNumber: interaction.customerNumber,
        externalReceiptId: getRandomId(),
        externalReferenceReceiptId: hasExternalReferenceReceiptId ? interaction.externalReceiptId : undefined,
        externalUnitNumber: interaction.externalUnitNumber,
        interactionTimestamp: getCurrentDateTimeAsString(),
        reversalAmount: interaction.paymentAmount,
        bonusXpress: interaction.bonusXpressCashValue
          ? {
              reversalCashValue: interaction.bonusXpressCashValue,
            }
          : undefined,
        coupons: interaction.selectedCoupons?.map((coupon) => ({
          couponCode: coupon.couponCode,
        })),
        receiptDetails: interaction.receiptDetails,
      },
      interaction.selectedCoupons
    );
  };

  if (!interaction) return null;
  const operationalUnit = operationalUnits.find(
    (operationalUnit) => operationalUnit.externalUnitNumber === interaction.externalUnitNumber
  );
  if (!operationalUnit) return null;
  const rootUnit = operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber);
  if (!rootUnit) return null;
  const amount = (interaction.reversalAmount ? -interaction.reversalAmount : interaction.paymentAmount)!;
  const bonusXpressBonusPoints =
    interaction.reversalResponse?.bonusXpress?.bonusPoints || interaction.paymentResponse?.bonusXpress?.bonusPoints;
  const bonusXpressDisplayedCashValue =
    interaction.bonusXpressReversalCashValue ||
    (interaction.bonusXpressCashValue && interaction.bonusXpressCashValue * -1);
  const bonusXpressCalculatedCashValue =
    (interaction.bonusXpressReversalCashValue && interaction.bonusXpressReversalCashValue * -1) ||
    interaction.bonusXpressCashValue;
  return (
    <>
      <Toast
        style={{
          fontFamily: "monospace",
          fontSize: "11.375px",
          padding: 16,
          maxWidth: 364,
          width: 364,
        }}
      >
        <p style={{ textAlign: "center" }}>
          <big>
            <b>Tamaris</b>
          </big>
          <br />
          {operationalUnit.unitName}

          {operationalUnit.contact && operationalUnit.contact.address && (
            <>
              <br />
              {operationalUnit.contact.address.streetName} {operationalUnit.contact.address.houseNumber}
              <br />
              {operationalUnit.contact.address.zipCode} {operationalUnit.contact.address.cityName}{" "}
            </>
          )}
        </p>
        {interaction.externalReferenceReceiptId && (
          <p style={{ textAlign: "center" }}>
            <b>STORNORECHNUNG FÜR BELEG {interaction.externalReferenceReceiptId}</b>
          </p>
        )}
        {interaction.externalCustomerId && (
          <>
            <div
              style={{
                textAlign: "right",
              }}
            >
              Kundennummer: {interaction.externalCustomerId}
            </div>
            <br />
          </>
        )}

        {interaction.receiptDetails?.map((detail) => (
          <div
            key={detail.itemInfo.refNumber}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{detail.itemInfo.supplierItemText?.padEnd(35)}</div>
            <div>{formatEuro(interaction.reversalAmount ? -Number(detail.salesPrice) : Number(detail.salesPrice))}</div>
          </div>
        ))}
        <p>------------------------------------------------</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>ZWISCHENSUMME</div>
          <div>{formatEuro(Number(amount))}</div>
        </div>
        <p>------------------------------------------------</p>
        {interaction.selectedCoupons?.map((coupon) => (
          <div
            key={coupon.couponCode}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {coupon.advantage.advantageText} ({coupon.couponCode})
            </div>
            <div>{formatEuro(-(calculateCoupon(amount, coupon) || 0))}</div>
          </div>
        ))}
        {bonusXpressBonusPoints && bonusXpressDisplayedCashValue && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Bonus-XPress ({Number(bonusXpressBonusPoints)} Punkte)</div>
              <div>{formatEuro(bonusXpressDisplayedCashValue)}</div>
            </div>
          </>
        )}

        {((bonusXpressBonusPoints && bonusXpressDisplayedCashValue) || interaction.coupons) && (
          <p>------------------------------------------------</p>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}
        >
          <div>SUMME</div>
          <div>
            {formatEuro(
              calculatePaymentAmountWithCouponAndXpress(
                amount,
                bonusXpressCalculatedCashValue,
                interaction.selectedCoupons
              )
            )}
          </div>
        </div>

        <p>================================================</p>
        <p>
          ************************************************
          <br />* {formatBonusPoints(interaction.paymentResponse, interaction.reversalResponse)}
          *
          <br />* {formatTotalBonusPoints(interaction.paymentResponse, interaction.reversalResponse)}
          *
          <br />
          ************************************************
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}
        >
          <div>
            Filiale: {interaction.externalUnitNumber}
            <br />
            Bon-Nr.: {interaction.externalReceiptId}
          </div>
          <div style={{ textAlign: "right" }}>
            {formatGermanDate(interaction.interactionTimestamp)}
            <br />
            {formatGermanTime(interaction.interactionTimestamp)}
          </div>
        </div>
      </Toast>
      <Row>
        <Col md={6}>
          <Button type="submit" className="mt-3" onClick={() => props.thunkResetInteraction()}>
            Neuer Einkauf
          </Button>
          {!interaction.reversalResponse && (
            <>
              <Button style={{ marginLeft: 16 }} type="submit" className="mt-3" onClick={() => reversal(true)}>
                Einkauf stornieren
              </Button>

              <Button style={{ marginLeft: 16 }} type="submit" className="mt-3" onClick={() => reversal(false)}>
                Storno ohne Bon
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkResetInteraction, thunkCreateReversalInteraction }, dispatch);

const mapStateToProps = (state: IStore) => ({
  interaction: state.touchpoint,
  operationalUnits: state.operationalUnit.operationalUnits,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptSimulator);
