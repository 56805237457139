import { OperationalUnitDto } from "../models/OperationalUnitDto";
import { PayloadAction } from "../actions/PayloadAction";
import { GET_OPERATIONAL_ROOT_UNIT_LIST, GET_BRANCH_STORE_LIST } from "../actions/OperationalUnitActions";
import { BranchStoreDto } from "../models/BranchStoreDto";

export interface IOperationalUnitReducerState {
  operationalUnits: OperationalUnitDto[];
  branchStores: BranchStoreDto[];
}

export const initialState: IOperationalUnitReducerState = {
  operationalUnits: [],
  branchStores: [],
};

export default class OperationalUnitReducer {
  private static readonly INITIAL_STATE: IOperationalUnitReducerState = initialState;

  public static reducer(
    state: IOperationalUnitReducerState = OperationalUnitReducer.INITIAL_STATE,
    action: PayloadAction<OperationalUnitDto[]>
  ): IOperationalUnitReducerState {
    switch (action.type) {
      case GET_OPERATIONAL_ROOT_UNIT_LIST:
        return {
          ...state,
          operationalUnits: action.payload!,
        };
      case GET_BRANCH_STORE_LIST:
        return {
          ...state,
          branchStores: action.payload!,
        };
      default:
        return state;
    }
  }
}
