import API from "../../api/Api";
import { BooleanThunk } from "../rootReducer";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY";
export const GET_BONUS_POINTS_INFO = "GET_BONUS_POINTS_INFO";

export const thunkGetCustomerHistory = (): BooleanThunk => async (dispatch) => {
  try {
    const bonifiedInteractions = await API.getCustomerHistory();
    dispatch({
      type: GET_CUSTOMER_HISTORY,
      payload: bonifiedInteractions,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Bonushistorie", e));
    return false;
  }
};

export const thunkGetBonusPointsInfo = (): BooleanThunk => async (dispatch) => {
  try {
    const result = await API.getBonusPointsInfo();
    dispatch({
      type: GET_BONUS_POINTS_INFO,
      payload: result,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Bonuspunkte", e));
    return false;
  }
};
