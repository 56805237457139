import API from "../../api/Api";
import { ConfigTypes } from "../models/FieldConfigDto";
import { RegistrationDto } from "../models/RegistrationDto";
import { BooleanThunk } from "../rootReducer";
import { thunkCreateErrorNotification } from "./NotificationActions";
import { ConfigGroupKey } from "../models/ConfigGroupKey";

export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_DISPLAY_CONFIG = "REGISTRATION_DISPLAY_CONFIG";
export const REGISTRATION_FIELD_CONFIG = "REGISTRATION_FIELD_CONFIG";

export const thunkGetRegistrationDisplayConfig = (): BooleanThunk => async (dispatch) => {
  try {
    const registrationDisplayConfig = await API.getRegistrationConfig([
      ConfigGroupKey.REGISTRATION_FIELD_DISPLAY_CONFIG,
    ]);

    dispatch({
      type: REGISTRATION_DISPLAY_CONFIG,
      payload: registrationDisplayConfig,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Can not get registration display config", e));
    return false;
  }
};

export const thunkGetRegistrationFieldConfig = (): BooleanThunk => async (dispatch) => {
  try {
    const registrationFieldConfig = await API.getRegistrationFieldConfig(ConfigTypes.LOYALTY);
    dispatch({
      type: REGISTRATION_FIELD_CONFIG,
      payload: registrationFieldConfig,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler bei der Ermittlung der Feldliste", e));
    return false;
  }
};

export const thunkRegistration =
  (registration: RegistrationDto): BooleanThunk =>
  async (dispatch) => {
    try {
      delete registration.login.passwordConfirm;
      registration.mailAddress = registration.login.username;

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const invitation = params.get("invitation") || undefined;

      // remove preselected country
      if (
        registration.address &&
        registration.address.country === "DE" &&
        !registration.address.streetName &&
        !registration.address.houseNumber &&
        !registration.address.zipCode &&
        !registration.address.cityName
      ) {
        delete registration.address;
      }

      const registrationResult = await API.register(registration, invitation);
      dispatch({
        type: REGISTRATION_SUCCESS,
        payload: registrationResult,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler bei der Registrierung", e));
      return false;
    }
  };
