import { v4 as uuid } from "uuid";
import NotificationDto, { NotificationType } from "../models/NotificationDto";
import { VoidThunk } from "../rootReducer";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const SHOW_INFO_SECONDS = 5;
export const SHOW_SUCCESS_SECONDS = 5;
export const SHOW_ERROR_SECONDS = 5;

export const thunkDismissNotification =
  (notification: NotificationDto): VoidThunk =>
  async (dispatch) => {
    dispatch({
      type: DISMISS_NOTIFICATION,
      payload: notification,
    });
  };

export const thunkCreateErrorNotification =
  (message: string, e: any): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(message + ": " + e, NotificationType.ERROR, SHOW_ERROR_SECONDS));
  };

export const thunkCreateInfoNotification =
  (message: string): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(message, NotificationType.INFO, SHOW_INFO_SECONDS));
  };

export const thunkCreateSuccessNotification =
  (message: string): VoidThunk =>
  async (dispatch) => {
    dispatch(thunkCreateNotification(message, NotificationType.CONFIRMATION, SHOW_SUCCESS_SECONDS));
  };

export const thunkCreateNotification =
  (message: string, type: NotificationType, timeoutSeconds?: number): VoidThunk =>
  async (dispatch) => {
    const notification = {
      message,
      type,
      id: uuid(),
      timestamp: new Date(),
    };

    dispatch({
      type: CREATE_NOTIFICATION,
      payload: notification,
    });

    timeoutSeconds &&
      timeoutSeconds !== 0 &&
      setTimeout(() => dispatch({ type: DISMISS_NOTIFICATION, payload: notification }), timeoutSeconds * 1000);
  };
