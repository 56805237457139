import { parseResponse, tokenRequestOptions } from "../store/actions/LoginActions";
import { BonusPointsDto } from "../store/models/BonusPointsDto";
import { BonusPremiumDto } from "../store/models/BonusPremiumDto";
import { BranchStoreDto } from "../store/models/BranchStoreDto";
import { ChangePasswordDto } from "../store/models/ChangePasswordDto";
import { ConsentDto } from "../store/models/ConsentDto";
import { CouponDto } from "../store/models/CouponDto";
import { CustomerDto } from "../store/models/CustomerDto";
import CustomerPreferenceDto from "../store/models/CustomerPreferenceDto";
import { ConfigTypes, FieldConfigDto } from "../store/models/FieldConfigDto";
import { ForgotPasswordDto } from "../store/models/ForgotPasswordDto";
import { CustomerHistoryDto } from "../store/models/HistoryDto";
import { InvitationMeDto } from "../store/models/InvitationMeDto";
import { OperationalUnitDto } from "../store/models/OperationalUnitDto";
import PreferenceDto from "../store/models/PreferenceDto";
import { RegistrationDto } from "../store/models/RegistrationDto";
import { ResetPasswordDto } from "../store/models/ResetPasswordDto";
import { ConfigGroupKey } from "../store/models/ConfigGroupKey";
import { fetchWithTenant } from "./ApiUtils";

export const REACT_APP_SERVER_URL = window.env.REACT_APP_SERVER_URL || process.env.REACT_APP_SERVER_URL;

const REACT_APP_BONUS_MANAGEMENT_URL =
  window.env.REACT_APP_BONUS_MANAGEMENT_URL || process.env.REACT_APP_BONUS_MANAGEMENT_URL;
const REACT_APP_CUSTOMER_CARE_URL = window.env.REACT_APP_CUSTOMER_CARE_URL || process.env.REACT_APP_CUSTOMER_CARE_URL;
const REACT_APP_CUSTOMER_INTERACTION_URL =
  window.env.REACT_APP_CUSTOMER_INTERACTION_URL || process.env.REACT_APP_CUSTOMER_INTERACTION_URL;
const REACT_APP_REGISTRATION_URL = window.env.REACT_APP_REGISTRATION_URL || process.env.REACT_APP_REGISTRATION_URL;
const REACT_APP_HISTORY_URL = window.env.REACT_APP_HISTORY_URL || process.env.REACT_APP_HISTORY_URL;

const customerCareUrl = REACT_APP_CUSTOMER_CARE_URL || `${REACT_APP_SERVER_URL}/customercare/v1`;
const registrationUrl = REACT_APP_REGISTRATION_URL || `${REACT_APP_SERVER_URL}/auth/v1`;
const customerInteractionUrl = REACT_APP_CUSTOMER_INTERACTION_URL || `${REACT_APP_SERVER_URL}/customerinteraction/v1`;
const bonusManagementUrl = REACT_APP_BONUS_MANAGEMENT_URL || `${REACT_APP_SERVER_URL}/bonusmanagement/v1`;
const historyUrl = REACT_APP_HISTORY_URL || `${REACT_APP_SERVER_URL}/history/v1`;

const objectToQueryString = (obj: object) => {
  const entries = Object.entries(obj).filter(([_, value]) => value !== undefined);
  return entries.length ? `?${entries.map(([key, value]) => key + "=" + value).join("&")}` : "";
};

const API = {
  findCustomer: async () => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerCareUrl}/loyalty/customers/me`, requestOptions).then(parseResponse);
  },
  updateCustomer: async (customer: CustomerDto) => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/loyalty/customers/me`, {
      ...requestOptions,
      body: JSON.stringify(customer),
    }).then(parseResponse);
  },
  register: async (registration: RegistrationDto, invitation?: string): Promise<CustomerDto> => {
    const requestOptions = await tokenRequestOptions("POST", false);
    return fetchWithTenant(
      `${registrationUrl}/loyalty/register${objectToQueryString({
        invitation,
      })}`,
      {
        ...requestOptions,
        body: JSON.stringify(registration),
      }
    ).then(parseResponse);
  },
  cancelMembership: async (): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerCareUrl}/loyalty/customers/me/cancel-membership`, {
      ...requestOptions,
    }).then(parseResponse);
  },
  blockCustomer: async (): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerCareUrl}/loyalty/customers/me/block`, {
      ...requestOptions,
    }).then(parseResponse);
  },
  consent: async (consent: ConsentDto): Promise<CustomerDto> => {
    const requestOptions = await tokenRequestOptions("POST", false);
    return fetchWithTenant(`${customerCareUrl}/customers/${consent.externalCustomerId}/consent`, {
      ...requestOptions,
      body: JSON.stringify(consent),
    }).then(parseResponse);
  },
  resendVerificationLink: async (customerNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST", false);
    return fetchWithTenant(`${registrationUrl}/register/${customerNumber}`, {
      ...requestOptions,
      body: "{}",
    }).then(parseResponse);
  },
  getCustomerPreferences: async (): Promise<PreferenceDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerCareUrl}/loyalty/customers/me/preferences`, requestOptions).then(parseResponse);
  },
  updateCustomerPreferences: async (customerPreferences: CustomerPreferenceDto[]) => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/loyalty/customers/me/preferences`, {
      ...requestOptions,
      body: JSON.stringify(customerPreferences),
    }).then(parseResponse);
  },
  getCustomerHistory: async (): Promise<CustomerHistoryDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${historyUrl}/loyalty/history/me`, requestOptions).then(parseResponse);
  },
  getBonusPointsInfo: async (): Promise<BonusPointsDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/loyalty/customers/me/points`, requestOptions).then(parseResponse);
  },
  findCoupons: async (): Promise<CouponDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/loyalty/customers/me/coupons`, requestOptions).then(
      parseResponse
    );
  },
  findCoupon: async (couponCode: string): Promise<CouponDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/loyalty/coupons/${couponCode}`, requestOptions).then(
      parseResponse
    );
  },
  createCoupon: async (templateNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerInteractionUrl}/loyalty/coupons/me`, {
      ...requestOptions,
      body: JSON.stringify({
        templateNumber,
      }),
    }).then(parseResponse);
  },
  findActivePremiums: async (): Promise<BonusPremiumDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/premiums`, requestOptions).then(parseResponse);
  },
  getOperationalRootUnitList: async (): Promise<OperationalUnitDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/units/rootlist`, {
      ...requestOptions,
    }).then(parseResponse);
  },
  getBranchStoreList: async (): Promise<BranchStoreDto[]> => {
    const requestOptions = await tokenRequestOptions("GET", false);
    return fetchWithTenant(`${customerInteractionUrl}/units/store`, {
      ...requestOptions,
    }).then(parseResponse);
  },
  changePassword: async (changePassword: ChangePasswordDto) => {
    const requestOptions = await tokenRequestOptions("PUT");
    delete changePassword.confirmNewPassword;

    return fetchWithTenant(`${registrationUrl}/loyalty/password`, {
      ...requestOptions,
      body: JSON.stringify(changePassword),
    }).then(parseResponse);
  },
  invite: async (invitation: InvitationMeDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${registrationUrl}/loyalty/invitations/me`, {
      ...requestOptions,
      body: JSON.stringify(invitation),
    }).then(parseResponse);
  },
  getRegistrationFieldConfig: async (type: ConfigTypes): Promise<FieldConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET", false);
    return fetchWithTenant(
      `${registrationUrl}/configs/registration/fields${objectToQueryString({
        type,
      })}`,
      {
        ...requestOptions,
      }
    ).then(parseResponse);
  },
  getRegistrationConfig: async (configGroups: ConfigGroupKey[]): Promise<FieldConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET", false);
    return fetchWithTenant(
      `${registrationUrl}/configs${objectToQueryString({
        configGroups,
      })}`,
      {
        ...requestOptions,
      }
    ).then(parseResponse);
  },
  getCustomerConfig: async (configGroups: ConfigGroupKey[]): Promise<FieldConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${customerCareUrl}/configs${objectToQueryString({
        configGroups,
      })}`,
      {
        ...requestOptions,
      }
    ).then(parseResponse);
  },
  getCustomerFieldConfig: async (type: ConfigTypes): Promise<FieldConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${customerCareUrl}/configs/customer/fields${objectToQueryString({
        type,
      })}`,
      {
        ...requestOptions,
      }
    ).then(parseResponse);
  },
  resetPassword: async (resetPassword: ResetPasswordDto) => {
    const requestOptions = await tokenRequestOptions("POST", false);
    return fetchWithTenant(`${registrationUrl}/loyalty/password-reset`, {
      ...requestOptions,
      body: JSON.stringify(resetPassword),
    }).then(parseResponse);
  },
  forgotPassword: async (forgotPassword: ForgotPasswordDto) => {
    const requestOptions = await tokenRequestOptions("POST", false);
    return fetchWithTenant(`${registrationUrl}/loyalty/forgot-password`, {
      ...requestOptions,
      body: JSON.stringify(forgotPassword),
    }).then(parseResponse);
  },
};

export default API;
