import { ConfigDto } from "./../models/ConfigDto";
import { CustomerDto } from "../models/CustomerDto";
import {
  CUSTOMER_DISPLAY_CONFIG,
  CUSTOMER_FIELD_CONFIG,
  FIND_CUSTOMER,
  UPDATE_CUSTOMER,
} from "../actions/CustomerActions";
import { FieldConfigDto } from "../models/FieldConfigDto";
import { AnyAction } from "redux";

export interface ICustomerReducerState {
  customer: CustomerDto;
  customerFieldConfig: FieldConfigDto[];
  customerDisplayConfig: ConfigDto[];
}

export const initialState: ICustomerReducerState = {
  customer: {
    customerNumber: "",
    mailAddress: "",
    address: {
      streetName: "",
      houseNumber: "",
      zipCode: "",
      cityName: "",
      country: "",
    },
  },
  customerFieldConfig: [],
  customerDisplayConfig: [],
};

export default class CustomerReducer {
  private static readonly INITIAL_STATE: ICustomerReducerState = initialState;

  public static reducer(
    state: ICustomerReducerState = CustomerReducer.INITIAL_STATE,
    action: AnyAction
  ): ICustomerReducerState {
    switch (action.type) {
      case FIND_CUSTOMER:
        return { ...state, customer: action.payload! };
      case UPDATE_CUSTOMER:
        return {
          ...state,
          customer: action.payload!,
        };
      case CUSTOMER_DISPLAY_CONFIG:
        return {
          ...state,
          customerDisplayConfig: action.payload,
        };
      case CUSTOMER_FIELD_CONFIG:
        return {
          ...state,
          customerFieldConfig: action.payload,
        };
      default:
        return state;
    }
  }
}
